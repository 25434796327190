.new-design-datepicker {
  .ui-datepicker {
    min-height: 272px;
    width: 446px;
    border: none;
    border-radius: 10px;
    padding: 8px;
    background: #DCDCDC;

    span, a {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #F8F3F3;
    }

    &-header {
      background: linear-gradient(180deg, #3F3F3F 0%, #2C2C2C 100%);
      border: none;
      border-radius: 8px;
      height: 36px;
      margin: 0;
      padding: 0;

      .ui-datepicker-title {
        position: absolute;
        width: 100%;
        margin: 0;
      }

      .ui-datepicker-title,
      .ui-corner-all
      {
        top: 50%;
        transform: translate(0, -50%);
      }

      .ui-corner-all {
        height: 24px;
        width: 24px;
        z-index: 2;
        cursor: pointer;

        &.ui-state-hover {
          border: none;
          background: none;

          &.ui-datepicker-prev:not(.ui-state-disabled) .ui-icon {
            transform: scale(1.1, 1.1);
          }
          &.ui-datepicker-next:not(.ui-state-disabled) .ui-icon {
            transform: scale(-1.1, 1.1);
          }
        }

        .ui-icon {
          height: 24px;
          width: 24px;
          background-size: cover;
          position: static;
          margin: 0;
          transition: all 0.1s linear;
          background: url("../images/datepicker-corner.svg") no-repeat;
        }
      }

      .ui-datepicker-prev  {
        left: 8px;
      }

      .ui-datepicker-next {
        right: 8px;

        .ui-icon {
          transform: scaleX(-1)
        }
      }
    }

    &-calendar {
      margin: 0;

      tr {
        display: flex;
        align-items: center;
      }

      th, td {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 58px;
      }

      thead {
        tr {
          justify-content: center;
        }

        th {
          height: 52px;

          span {
            color: #000000;
          }
        }
      }

      tbody {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4px;

        tr {
          justify-content: space-between;
          gap: 4px;
        }

        td {
          height: 30px;
          opacity: 1!important;
          border: none!important;

          span, a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            border: none;
            border-radius: 4px;
            background: #58A0DF;
            color: #F8F3F3!important;
          }

          &.ui-state-disabled,
          &.ui-state-date-disabled {
            pointer-events: none;

            span, a {
              background: #E5373F;
            }
          }

          &.ui-datepicker-today {
            span, a {
              background: #FFAC00;
            }
          }

          &.ui-state-disabled.ui-datepicker-today {
            span, a {
              background: #bd4f00;
            }
          }

          &.ui-state-past-day {
            span, a {
              background: linear-gradient(180deg, #3F3F3F 0%, #2C2C2C 100%);
            }
          }

          &.ui-datepicker-current-day {
            span, a {
              transform: rotate(357deg);
              box-shadow: 0 1px 5px #656565;
              cursor: default;
            }
          }

          &:not(.ui-state-disabled):not(.ui-datepicker-current-day) {
            .ui-state-hover {
              transform: scale(1.1);
              box-shadow: 0 1px 5px #656565;
            }
          }
        }
      }
    }
  }

  .scheduler {
    display: flex;
    gap: 24px;
    justify-content: center;

    &-left {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 16px;
      background: #404040;
      border-radius: 16px;

      .day-part {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 133px;
        height: 100%;
        padding: 8px;
        border-radius: 10px;
        background: #DCDCDC;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          border-radius: 8px;
          background: #C1C1C1;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          position: unset;

          &.time-title {
            background: linear-gradient(180deg, #3F3F3F 0%, #2C2C2C 100%);
            color: #F8F3F3;
          }
        }
      }
    }

    &-right {
      .scheduler-calendar {
        padding: 16px;
        background: #404040;
        border-radius: 16px;
      }

      .scheduler-colors {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px 0;

        .scheduler-color {
          display: flex;
          align-items: center;
          gap: 8px;

          .color-box {
            height: 20px;
            width: 28px;
            border-radius: 4px;

            &.available {background: #58A0DF}
            &.current {background: #FFAC00}
            &.unavailable {background: #E5373F}
          }

          .color-text {
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin: 0;
          }
        }
      }
    }
  }
}
