.chat-html {
  .chat-center-panel .video .chat-info.scheduled-show {
    display: none;
  }

  &.ScheduledShowStared {
    .chat-left-panel {
      .tippers {
        display: none;
      }
      .menu {
        .gold-show-options {
          display: none;
        }
        .options {
          .allow-group,
          .allow-private,
          .allow-voyeur {
            display: none !important;
          }
        }
      }
    }
    .chat-center-panel .video .chat-info {
      display: none;
      &.scheduled-show {
        display: inline-block;
      }
    }

  }

}